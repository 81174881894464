

.GSTAddReviewFinalBhilosa1{
  display:flex;
}

.UploadContainer1 {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  margin-top: 10px;
}
  .Header1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    width: 100%;
  }
  .UploaderWidget1 {
    width: 100%;
    margin-top: 12px;
  }
  .UploaderFooter1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 12px;
  }
  .ActionContainerBhilosa1 {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  //   margin-top: 24px;
  margin-top: 50px;
  }
