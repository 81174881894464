// .donut-container {
//     width: 700px;
//     height: 550px;
//     padding: 20px;
//     background: #fff; /* White background */
//     border-radius: 12px;
//     border: 1px solid #ddd; /* Light border */

//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     margin-top: 20px;
//     margin-left: 20px;
//   }

//   .heading{
//     font-family: "Noto Sans";
//     margin-top: 20px;
//     display: flex;
//     font-size: 18px;
//     color: var(--Gray-Gray-700, #2D3748);
//     font-weight: 700;
//     line-height: 140%; /* 25.2px */
// }

.donutContainer {
  // height:500px;
  // width:900px;
  // margin: 20px;//for now
  // display: flex;
  // flex-direction: column;
  //  min-height: 380px;
  min-height: 400px;

  width: 100%;
  //margin: 20px; //for now
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid var(--Neutral-400, #dfe0e7);
  background: var(--Neutral-50, #fff);
  box-shadow: 114px 124px 47px 0px rgba(0, 0, 0, 0),
    73px 79px 43px 0px rgba(0, 0, 0, 0.01),
    41px 45px 36px 0px rgba(0, 0, 0, 0.02),
    18px 20px 27px 0px rgba(0, 0, 0, 0.03), 5px 5px 15px 0px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
}

.heading {
  font-family: "Noto Sans";
  margin-top: 24px;
  display: flex;
  font-size: 18px;
  color: var(--Gray-Gray-700, #2d3748);
  font-weight: 700;
  margin-left: 24px;
}

.donut {
  margin-right: 30px;
  margin-top: 20px;
  width: 100%;
 // height:500px
 
}
