/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.settinghead{
  color: var(--Neutral-800, #3F4047);
/* Medium Head/Medium H2 */
font-family: "Noto Sans";
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-top: 4px;
}

.navbar-left h2 {
  margin-top: 20px !important;
  font-size: 18px;
}
.navbar-left{
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.navbar-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.entity-select {
  padding: 5px 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-member-btn {
  background-color: #0A8394;
  color: #fff;
  border: none;
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  gap: 13px;
  justify-content: center;
  align-items: center;
}



/* Tabs */
.tabs {
  display: flex;
  border-bottom: white;
  margin-top: 10px;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  color: #A3A6B7;
  border-bottom: white;
  transition: all 0.3s;
}



.active-tab {
  border-bottom: 2px solid #0A8394;
  font-weight: bold;
  color: #0A8394;
}

/* Tab Content */
.tab-content {
  padding: 10px;
  font-size: 14px;
  /* background-color: #FBFCFF; */
  border: 1px solid #E8EAF1;
  margin-top: -1px;
}
