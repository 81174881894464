.invoiceContainer {
  width: 100%;
  min-height: 228px;

  //margin: 20px; //for now
  //margin-top: 20px;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid #dfe0e7;
  background: #fff;
  box-shadow: 114px 124px 47px 0px rgba(0, 0, 0, 0),
    73px 79px 43px 0px rgba(0, 0, 0, 0.01),
    41px 45px 36px 0px rgba(0, 0, 0, 0.02),
    18px 20px 27px 0px rgba(0, 0, 0, 0.03), 5px 5px 15px 0px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
}

.einvoice-toggle-switch {
  border-radius: 4px;
  background: var(--Neutral-100, #f8f9fb);
  display: flex;
  width: 200px;
  padding: 4px;
  align-items: center;
}

.einvoice-toggle-option {
  flex: 1;
  text-align: center;
  font-family: "Noto Sans";
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  z-index: 2;
  color: #a3a6b7; // Default inactive color
  //   margin-top: 5px;

  &.active {
    color: var(--Primaty2-Blue-700, #0a8394);
    border: 1px solid rgba(0, 132, 139, 0.2);
    border-radius: 4px;
    background-color: #ebfffe80;
    font-weight: 500;
  }
}

.einvoicecard-title {
  color: var(--Neutral-950, #212121);
  /* Regular Head/Regular H1 */
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 15px;
  margin-bottom: 4px;
}

.einvoicecard-form-group {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  margin-top: 12px;
  box-sizing: border-box;
  width: 50%;
}

.einvoicecard-input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  //width: 192px;
  width: 50%;

  label {
    color: var(--Neutral-950, #212121);
    font-family: "Noto Sans";
    font-size: 12px;
    font-style: normal;
    //font-weight: 400;
    line-height: normal;
    opacity: 0.4;
  }

  span {
    color: #e53232;
  }

  input {
    // color: var(--Neutral-950, #212121);
    color: rgba(33, 33, 33, 1);
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 4px;
    border: 1px solid var(--Neutral-400, #dfe0e7);
    background: var(--Neutral-50, #fff);
    padding: 6px 9px;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
  }

  input::placeholder {
    color: var(--Neutral-950, #212121);
    font-size: 12px;
    
  }

  select {
    display: flex;
    height: 33px;
    padding: 6px 12px;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--Neutral-400, #dfe0e7);
    background: var(--Neutral-50, #fff);
  }
}

.einvoicecard-reset {
  display: flex;
  width: 48px;
  padding: 8px 15px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  border-radius: 4px;
  border: 0.75px solid var(--Primaty2-Blue-700, #0a8394);
}

.restBtn {
  border-radius: 4px;
  border: 0.75px solid var(--Primaty2-Blue-700, #0a8394);
  height:40px;
}
.validateBtn {
  background-color: #0a8394;
  color: white;
  margin-left: 12px;
  border-radius: 4px;
  border-color: #0a8394;
  height:40px;
  
}
.panValue{
  font-size: 14px;
}
.gstinValue{
  font-size: 14px;
}



.custom-select .ant-select-selection-item {
  color: black !important; /* Fix for selected text */
}

.custom-select .ant-select-item {
  color: black !important; /* Fix for dropdown items */
}

.custom-select .ant-select-selector {
  color: black !important; /* Fix for the main select box */
}

.custom-select .ant-select-selection-placeholder {
  color: rgba(33, 33, 33, 1);
  font-family: "Noto Sans";
  font-size: 12px;/* Placeholder should be gray */
}


// .invoiceHeading {
//     border-radius: 4px;
//     background: var(--Neutral-100, #F8F9FB);
//     display: flex;
//     width: 200px;
//     padding: 4px;
//     align-items: center;
// }

// .btn1 {
//     border-radius: 4px;
//     border: 1px solid rgba(0, 132, 139, 0.20);
//     background: rgba(235, 255, 254, 0.50);
//     box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05);
//     display: flex;
//     padding: 4px 20px;
//     justify-content: center;
//     align-items: center;
//     gap: 8px;
//     flex: 1 0 0;
//     align-self: stretch;
//     color: var(--Primaty2-Blue-700, #0A8394);
//     font-family: "Noto Sans";
//     font-size: 14px;
//     font-style: normal;
//     font-weight: 700;
//     line-height: normal;
// }

// .btn2 {
//     display: flex;
//     padding: 4px 20px;
//     justify-content: center;
//     align-items: center;
//     gap: 8px;
//     flex: 1 0 0;
//     align-self: stretch;
//     border-radius: 4px;
//     color: var(--Neutral-600, #A3A6B7);
//     font-family: "Noto Sans";
//     font-size: 14px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: normal;
// }

// .headerFirst {
//     color: var(--Neutral-950, #212121);
//     font-family: "Noto Sans";
//     font-size: 20px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: normal;
//     margin-top: 15px;
// }
