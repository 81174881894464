.addBtnCred{
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
  margin-right: 20px;
}
.tableAll{
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
}