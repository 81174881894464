.vendor-management {
    //padding: 20px;
    //padding-top: 20px;
    //padding-bottom: 20px;
    padding-right: 20px;
  
    .vendor-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 8px;
  
      h1 {
        font-size: 20px;
        font-family: "Noto Sans";
        font-style: normal;
        font-weight: 500;
        color: var(--Neutral-800, #3F4047);
      }

      .vendor-back-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #0A8394;
        background-color: transparent;
        border-radius: 5px;
        padding: 4px 6px;
      }
  
    
      .vendor-left{
        display: flex;
        gap: 15px;
        justify-content: center;
        align-items: center;
      }
      .vendor-search-container {
        display: flex;
        align-items: center;
        position: relative;
  
        .vendor-search-bar {
          padding: 8px;
          font-size: 14px;
          border: 1px solid #ccc;
          border-radius: 4px;
          width: 300px;
          padding-left: 30px; // Space for the search icon
        }
  
        .vendor-search-icon {
          position: absolute;
          left: 8px;
          top: 50%;
          transform: translateY(-50%);
          width: 20px;
          height: 20px;
          fill: #c3c4cd;
          pointer-events: none;
        }
      }
      .vendor-btn{
        display: flex;
        height: 40px;
        border: none;
        padding: 16px 60px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;
        border-radius: 4px;
        background: var(--Primaty2-Blue-700, #0A8394);
        color: var(--Neutral-50, #FFF);
        font-family: "Noto Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  
    .vendor-cards {
      display: flex;
      gap: 20px;
      width: 100%;
      margin: 20px 0;
  
      .vendor-card {
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        width: 100%;
        border-radius: 8px;
        text-align: center;
        border-radius: 4px;
        border: 1px solid var(--Neutral-300, #E8EAF1);
        background: var(--Neutral-100, #F8F9FB);
  
        h4 {
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
        }
  
        p {
          font-size: 14px;
          color: #666;
        }
      }
    }
  
    .table-container {
      .eligiblestatus{
        display: flex;
align-items: center;
border-radius: 2px;
border: 1px solid var(--Neutral-200, #EDEFF5);
height: 25px;
padding: 0px 10px;
      }
      .dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 8px;
  
        &.green {
          background: #4caf50;
        }
  
        &.red {
          background: #f44336;
        }
        &.orange{
          background-color: orange;
        }
      }
  
      .contact-status {
        &.completed {
          color: #1E7734;
          font-weight: 400;
          background-color: #C0F2CC;
          border-radius: 20px;
          // padding-left: 10px;
          // padding-right: 10px;
          height: 25px;
          width: 100px;
          gap: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &.contact {
          color: #0A8394;
          font-weight: 400;
          border:2px solid #0A8394;
          border-radius: 4px;
          height: 30px;
          width: 130px;
          gap: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
  
        &.pending {
          color: #2355D2;
          // padding-left: 10px;
          // padding-right: 10px;
          background-color: #C1DCFC;
          font-weight: 400;
          border-radius: 20px;
          height: 25px;
          width: 80px;
          gap: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
  
      .action-button {
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        border: none;
        color: #0A8394;
        cursor: pointer;
        text-decoration: underline;
      }

      .action-tick{
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        border: none;
        color: #0A8394;
        cursor: pointer;
      }
    }
    
  }
  

  
  .vendorhome-container {
    padding: 20px;
  
    /* Top Row Styles */
    .vendorhome-top-row {
      display: flex;
      flex-direction: column;
      gap: 20px;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      border-radius: 8px;
      padding: 20px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  
      .vendorhome-row {
        display: flex;
        gap: 20px;
  
        @media (max-width: 768px) {
          flex-direction: column;
        }
      }
  
      .vendorhome-left-section {
        flex: 1;
  
        .vendorhome-title {
          font-size: 1.5rem;
          margin-bottom: 10px;
          color: #333;
        }
  
        .vendorhome-description {
          font-size: 1rem;
          margin-bottom: 20px;
          color: #666;
        }
  
        .vendorhome-buttons {
          display: flex;
          gap: 10px;
  
          .vendorhome-btn {
            padding: 10px 20px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            font-size: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
  
            &-outline {
              background: #fff;
              border: 1px solid #007bff;
              color: #007bff;
            }
  
            &-primary {
              background: #007bff;
              color: #fff;
            }
          }
        }
      }
  
      .vendorhome-right-section {
        flex: 1;
  
        .vendorhome-login-card {
          background: #f9f9f9;
          border-radius: 8px;
          padding: 20px;
  
          .vendorhome-login-title {
            font-size: 1.2rem;
            margin-bottom: 10px;
          }
  
          .vendorhome-login-form {
            display: flex;
            flex-direction: column;
            gap: 10px;
  
            .vendorhome-input {
              padding: 10px;
              border: 1px solid #ccc;
              border-radius: 4px;
              font-size: 1rem;
            }
          }
        }
      }
    }
  
    /* Bottom Row Styles */
    .vendorhome-bottom-row {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
  
      .vendorhome-card {
        background: #ffffff;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        padding: 20px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        flex: 1;
        min-width: 300px;
  
        .vendorhome-title {
          font-size: 1.5rem;
          margin-bottom: 10px;
          color: #333;
        }
  
        .vendorhome-description {
          font-size: 1rem;
          margin-bottom: 20px;
          color: #666;
        }
  
        .vendorhome-list {
          padding-left: 20px;
          margin-bottom: 20px;
  
          li {
            font-size: 1rem;
            color: #666;
            margin-bottom: 10px;
          }
        }
  
        .vendorhome-btn {
          padding: 10px 20px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 1rem;
          background: #007bff;
          color: #fff;
        }
      }
    }
  }
  
