.vendorContainer {
  min-height: 228px;
  // width: 650px;
  width: 100%;
  //margin-top: 20px; //for now
  //margin-left:20px;
  //margin-bottom: 20px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid var(--Neutral-400, #dfe0e7);
  background: #fff;
  box-shadow: 114px 124px 47px 0px rgba(0, 0, 0, 0),
    73px 79px 43px 0px rgba(0, 0, 0, 0.01),
    41px 45px 36px 0px rgba(0, 0, 0, 0.02),
    18px 20px 27px 0px rgba(0, 0, 0, 0.03), 5px 5px 15px 0px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
}

h2 {
  color: var(--Neutral-950, #212121);
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px !important;
}
.vendorRequired {
  color: #212121;
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.4;
}
// span{
//     color: #E53232;
// }
.required {
  color: #e53232;
}
.input {
  display: flex;
  height: 30px;
  padding: 2px 12px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--Neutral-400, #dfe0e7);
  background: var(--Neutral-50, #fff);
  margin-top: 4px !important;
}
.input::placeholder {
  color: var(--Primaty2-Blue-700, #0a8394);
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.check-btn {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
  border-radius: 4px;
  background: var(--Primaty2-Blue-700, #0a8394);
  color: white;
  margin-top: 20px;
  height: 40px;
}
