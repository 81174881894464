.expense-card-container {
  padding: 24px;
  border-radius: 12px;
  width: 100%;
  //min-height: 360px;
  min-height: 400px;
  //margin-left:15px;
  border: 1px solid var(--Neutral-400, #dfe0e7);
  background: var(--Neutral-50, #fff);
  box-shadow: 114px 124px 47px 0px rgba(0, 0, 0, 0),
    73px 79px 43px 0px rgba(0, 0, 0, 0.01),
    41px 45px 36px 0px rgba(0, 0, 0, 0.02),
    18px 20px 27px 0px rgba(0, 0, 0, 0.03), 5px 5px 15px 0px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
}

.expense-card-header {
  // margin-bottom: 1rem;
  margin-bottom: 20px;
  .expenseTag {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    line-height: 25.2px;
    color: var(--Gray-Gray-700, #2d3748);
  }
  .expenseTagYear {
    font-size: 14px;
    color: #a0aec0;
  }
  .expenseTagButton {
    // width: 125px;
    height: 30px;
    font-size: 12px;
    font-family: Noto Sans;
    color: #0a8394;
    border: 1px solid #0a8394;
  }
}

.expense-card-body {
  display: flex;
  flex-direction: column;
  // gap: 1rem;
  gap: 20px;
}

.expense-card-box1 {
  background-color: #fff;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  background-color: #0a8394;
  gap: 18px;

  .expense-card-box-label {
    font-size: 14px;
    color: #f8f9fb;
  }

  .expense-card-box-value {
    font-size: 24px;
    font-weight: bold;
    color: #f8f9fb;
  }
}
.expense-card-box2 {
  background-color: #fff;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  background-color: #0a8394;
  gap: 18px;

  .expense-card-box-label {
    font-size: 14px;
    color: #f8f9fb;
  }

  .expense-card-box-value {
    font-size: 24px;
    font-weight: bold;
    color: #f8f9fb;
  }
}
.expense-card-box3 {
  background-color: #fff;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  background-color: #f5a623;
  gap: 18px;

  .expense-card-box-label {
    font-size: 14px;
    color: #f8f9fb;
  }

  .expense-card-box-value {
    font-size: 24px;
    font-weight: bold;
    color: #f8f9fb;
  }
}

/* Custom styles for the DatePicker to mimic a button with an icon on the left */
.apply-filter-datepicker {
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid #0A8394;
  border-radius: 4px;
  padding: 12px 15px 12px 15px; // extra left padding for the icon
  background-color: #fff;
  color: rgba(10,131,148,1);
  font-weight: bold;
  cursor: pointer !important;
  text-align: left;
  display: inline-block;
  width:127px;
  height:32px
}

.apply-filter-datepicker input {
  cursor: pointer !important;
}


.apply-filter-datepicker input::placeholder {
  color: #0a8394 !important;
}

/* Only hide the default DatePicker suffix icon */
.apply-filter-datepicker .ant-picker-suffix {
  display: none;
}

/* Style the input text if needed */
.apply-filter-datepicker .ant-picker-input input {
 font-size: 12px;
 color: rgba(10,131,148,1) in !important;
 line-height: 100%;
 margin-left: 17px;

}

/* Insert your custom SVG icon on the left side */
.apply-filter-datepicker::before {
  content: '';
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-image: url('data:image/svg+xml;utf8,<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.16797 2.66666H13.8346V4.11466C13.8346 4.46825 13.694 4.80733 13.444 5.05732L10.5013 7.99999V12.6667L6.5013 14V8.33332L3.51464 5.04799C3.2916 4.80261 3.168 4.48292 3.16797 4.15132V2.66666Z" stroke="%230A8394" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  width: 17px;
  height: 16px;
}
