/* Popup container */
.popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000; /* Ensure it appears above other elements */
    background-color: #fff;
    padding: 24px;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 90%; /* Adjust for responsiveness */
    max-width: 400px;
    animation: popup-fade-in 0.3s ease-out;
    font-family: 'Noto Sans', sans-serif;
    box-sizing: border-box; /* Ensures padding doesn't affect width */
}

/* Emoji style inside the popup */
.popup-emoji {
    font-size: 3rem;
    margin-bottom: 16px;
}

/* Paragraph style inside popup */
.popup-content p {
    margin: 12px 0;
    color: #333;
    font-size: 1rem;
    line-height: 1.5;
}

/* Buttons inside the popup */
.popup-content button {
    margin-top: 16px;
    padding: 10px 16px;
    background-color: #0A8394;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s ease-in-out;
    display: inline-block;
    text-align: center;
    width: 100%; /* Full width for better mobile responsiveness */
}

/* Button hover effect */
.popup-content button:hover {
    background-color: #0A8394;
}

/* Overlay behind the popup */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 999; /* Ensure it appears below the popup but above other content */
    animation: overlay-fade-in 0.3s ease-out;
}

/* Keyframes for popup fade-in animation */
@keyframes popup-fade-in {
    from {
        opacity: 0;
        transform: translate(-50%, -60%);
    }
    to {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}

/* Keyframes for overlay fade-in animation */
@keyframes overlay-fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Mobile responsiveness adjustments */
@media (max-width: 600px) {
    .popup-container {
        width: 80%; /* Make the popup container smaller on mobile devices */
        padding: 16px; /* Less padding for smaller screens */
    }

    .popup-emoji {
        font-size: 2rem; /* Slightly smaller emoji */
    }

    .popup-content button {
        padding: 8px 12px; /* Smaller buttons on mobile */
    }
}
/* Style for the remove button */
.remove-button {
    background-color: #0A8394;
    color: #fff;
    border: none;
    padding: 6px 16px;
    border-radius: 5px;
    cursor: pointer;
    display:flex;
    font-size: 14px;
    text-align: center;
    align-items: center;
    transition: background-color 0.3s ease;
}

.remove-button:hover {
    background-color:#0A8394;
}