.dashboard {
  display: flex;
  flex-direction: column;
  height: 180vh;
  width:170vh;
  row-gap: 13px;

  .dashboard-header {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .header-btn {
      margin: 40px 60px 10px 10px;
      width: 154px;
      padding: 9px 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      display: flex;
      color: #0A8394;
      border: 1px solid;
    }
  }

  .dashboard-header img {
    width: 60px;
    padding: 27px 10px 10px 80px;
  }


  .container-first-row {
    // width: 108%;
    margin: 0px 40px;
    display: flex;
    box-sizing: border-box;
    // padding: 28px 32px;
    column-gap: 20px;
    border-radius: 20px;
    background:#9ca3af36;
    box-shadow: 0px 4px 15px 0px #E8EAF1;
    height: 44vh;
    padding: 24px;


    .header {
      margin-bottom: 32px;
      width: 60%;

      .integration-badge {
        display: flex;
        padding: 4px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        width: 12%;
        border-radius: 72px;
        color: #0A8394;
        border: 1px solid;
        font-family: "Noto Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .title-container {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 16px;

        .icon {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f3f4f6;
          border-radius: 4px;
          color: #4b5563;
        }

        h2 {
          font-family: "Noto Sans";
          font-size: 32px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .description {
        margin-top: 16px;
        color: #3F4047;
        font-family: "Noto Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 71%;
      }

      .button-group {
        display: flex;
        gap: 16px;
        margin-top: 24px;

        .invite-button {
          display: flex;
          padding: 12px 15px;
          justify-content: center;
          align-items: center;
          gap: 6px;
          align-self: stretch;
          color: #0A8394;
          border: 1px solid;
          border-radius: 4px;
          flex: 1 0 0;
          background: #ffffff;

          &:hover {
            background-color: #ebf5ff;
          }
        }

        .add-pan-button {
          display: flex;
          height: 40px;
          padding: 16px 20px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          flex: 1 0 0;
          border-radius: 4px;
          cursor: pointer;
          background-color: #0A8394;
          border: 1px solid;
          color: #e6f7f8;
          flex: 1 0 0;

          &:hover {
            background-color: #0f766e;
          }
        }
      }

      .first-row-info {
        color: var(--Neutral-700, #72747D);
        font-family: "Noto Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 13px;
      }
    }

    .entities-section {

      .underline-1 {
        width: 99%;
        height: 2%;
        background: #EDEFF5;
        margin-bottom: 30px;
      }

      .entities-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 16px;
        width: 100%;

        h3 {
          font-family: "Noto Sans";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .view-detail {
          color: var(--Primaty2-Blue-700, #0A8394);
          font-family: "Noto Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-decoration-line: underline;
          text-underline-position: from-font;
          border: none;
          background-color: #ffffff;
          cursor: pointer;
          width: 25%;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .entities-list {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .entity-card {
          padding: 16px;
          border-radius: 12px;
          background: var(--Neutral-50, #FFF);
          box-shadow: 0px 4px 15px 0px #E8EAF1;

          .entity-header {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 12px;

            .pan-label {
              color: #4b5563;
            }


            .pan-value {
              color: var(--Primaty2-Blue-700, #0A8394);
              font-family: "Noto Sans";
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }

            .status-active {
              margin-left: auto;
              color: #059669;
              font-size: 12px;
              border-radius: 2px;
              border: 1px solid var(--Neutral-200, #EDEFF5);
              background: var(--Neutral-50, #FFF);
              padding: 2px 8px 2px 6px;
            }
          }


          .entity-header img {
            width: 25px;
          }

          .entity-details {
            display: flex;
            flex-direction: column;

            .detail-group {
              display: flex;
              justify-content: space-between;
              margin-top: 5px;

              .label {
                color: var(--Neutral-600, #A3A6B7);
                /* Base Text/Regular S */
                font-family: "Noto Sans";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              .value {
                color: #0A8394;
                text-align: right;
                font-family: "Noto Sans";
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
            }

            .status-badge {
              display: inline-block;
              padding: 4px 8px;
              border-radius: 4px;
              font-size: 12px;

              &.completed {
                background-color: #ecfdf5;
                color: #059669;
              }

              &.pending {
                background-color: #ebf5ff;
                color: #2563eb;
              }
            }
          }
        }
      }
    }
  }

  .container-second-row {
    // padding: 20px;
    background: #f8f9fa;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
    display: flex;
    // width: 96%;
    // column-gap: 27px;
    box-sizing: border-box;
    // margin: 0 42px;

    .dashboard-section {
      background: white;
      border-radius: 12px;
      // padding: 24px;
      margin-bottom: 24px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      // width: 50%;
      box-sizing: border-box;

      .section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        .header-tabs {
          display: flex;
          gap: 12px;

          .tab-button {
            padding: 6px 12px;
            border: none;
            background: none;
            border-radius: 16px;
            font-size: 14px;
            cursor: pointer;

            &.active {
              color: var(--Primaty2-Blue-700, #0A8394);
              border: 1px solid var(--Primaty2-Blue-700, #0A8394)
            }
          }
        }
      }

      .section-subheading {
        display: flex;
        justify-content: space-between;
        font-family: "Noto Sans";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 9px;

        .view-detail {
          color: #00a3b1;
          text-decoration: none;
          font-size: 14px;

        }
      }


      .stats-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
        gap: 24px;
        height: 85%;

        .stat-card {
          background: white;
          border-radius: 8px;
          padding: 16px;
          border-radius: 8px;
          border: 1px solid var(--Neutral-300, #E8EAF1);
          background: var(--Neutral-50, #FFF);
          box-shadow: 0px 4px 15px 0px #E8EAF1;
          width: 80%;

          .stat-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;



            .stat-icon {
              width: 32px;
              height: 32px;
              border-radius: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: white;
              font-weight: 500;

              &.total {
                background: #03A5B7;
              }

              &.compliant {
                background: #03A5B7;
              }

              &.non-compliant {
                background: #03A5B7;
              }

              &.missing {
                background: #03A5B7;
              }
            }
          }


          .more-options {
            border: none;
            background: none;
            color: #64748b;
            cursor: pointer;
            font-size: 20px;
            padding: 4px;
          }

          .underline {
            width: 99%;
            height: 2%;
            background: #EDEFF5;
            margin-bottom: 30px;
          }

          .stat-info {
            display: flex;
            gap: 4px;

            .stat-title {
              color: #64748b;
              font-size: 14px;
            }

            .stat-value {

              color: var(--Neutral-800, #3F4047);
              font-family: "Noto Sans";
              font-size: 24px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;

            }

            .stat-subtitle {
              color: var(--Neutral-600, #A3A6B7);
              font-size: 12px;
              display: flex;
              align-items: center;
            }
          }
        }
      }

      .section-content {
        display: flex;
        gap: 40px;

        .eligibility-chart {
          flex: 1;

          .chart-container {
            height: 300px;
            width: 100%;
          }

          h3 {
            font-size: 16px;
            margin-bottom: 20px;
            font-weight: 500;
          }
        }

        .msme-stats {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 24px;

          .stat-bar-container {
            border-radius: 8px;
            border: 1px solid var(--Neutral-300, #E8EAF1);
            background: var(--Neutral-50, #FFF);
            box-shadow: 0px 4px 15px 0px #E8EAF1;
            padding: 29px 12px;

            h3 {
              font-size: 16px;
              margin-bottom: 12px;
              font-weight: 500;
            }

            .stat-bar {
              height: 32px;
              background: #f1f5f9;
              border-radius: 4px;
              overflow: hidden;

              .stat-bar-fill {
                height: 100%;
                background: #00a3b1;
                display: flex;
                align-items: center;
                padding: 0 12px;
                transition: width 0.3s ease;

                .stat-label {
                  color: white;
                  color: var(--Neutral-700, #72747D);
                  font-family: "Noto Sans";
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }

            .stat-subtitle {
              color: #64748b;
              font-size: 10px;
              margin-top: 4px;
              text-align: right;
            }
          }


        }
      }
    }
  }
}

.onboarding-dashboard {
  background-color: #f5f6f8;
  min-height: 100vh;

  .dashboard-header {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .header-btn {
      margin: 40px 60px 10px 10px;
      width: 154px;
      padding: 9px 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      display: flex;
      color: #0A8394;
      border: 1px solid;
    }
  }

  .dashboard-header img {
    width: 60px;
    padding: 27px 10px 10px 80px;
  }

  .content-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    padding: 24px;
    max-width: 1400px;
    margin: 0 auto;

  }

  .card {
    background: white;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  .integration-section {
    .integration-badge {
      display: inline-block;
      padding: 6px 12px;
      color: var(--Primaty2-Blue-700, #0A8394);
      font-size: 14px;
      margin-bottom: 16px;
      border-radius: 72px;
      border: 1px solid var(--Primaty2-Blue-700, #0A8394)
    }

    .title-container {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 16px;

      img {
        width: 35px;
        height: 35px;
      }

      h2 {
        font-size: 24px;
        margin: 0;
      }
    }

    .description {
      color: #4A5568;
      margin-bottom: 24px;
      line-height: 1.5;
    }

    .button-group {
      display: flex;
      gap: 16px;
      margin-bottom: 16px;

      button {
        padding: 10px 20px;
        border-radius: 6px;
        cursor: pointer;

        &.invite-button {
          display: flex;
          align-items: center;
          gap: 8px;
          border: 1px solid #1E88E5;
          color: #1E88E5;
          background: white;
        }

        &.add-pan-button {
          background: #008080;
          color: white;
          border: none;
        }
      }
    }

    .info-text {
      color: #718096;
      font-size: 14px;
    }
  }

  .portal-preview {
    img {
      width: 100%;
      border-radius: 8px;
      border: 1px solid #e1e4e8;
    }
  }

  .info-card {
    .demo-badge {
      display: inline-block;
      padding: 6px 12px;
      background-color: #F7FAFC;
      font-size: 14px;
      color: var(--Primaty2-Blue-700, #0A8394);
      margin-bottom: 16px;
      border-radius: 72px;
      border: 1px solid var(--Primaty2-Blue-700, #0A8394);
    }

    h3 {
      color: var(--Neutral-950, #212121);
      font-family: "Noto Sans";
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 16px;
    }

    .content {
      color: #4A5568;
      margin-bottom: 24px;
      line-height: 1.5;
    }

    .vendor-list {
      list-style-type: disc;
      padding-left: 20px;
      margin-bottom: 24px;

      li {
        margin-bottom: 8px;
        color: #4A5568;
      }
    }

    .continue-button {
      width: 100%;
      padding: 12px;
      background: #EBF5FF;
      color: #1E88E5;
      border: none;
      border-radius: 6px;
      cursor: pointer;
      text-align: center;

      &:hover {
        background: #E3F2FF;
      }
    }
  }
}